import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/pt-br';
import React, { Component, useContext } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert';
import intl from 'react-intl-universal';
import { STATUS } from 'react-joyride';
import { Button } from 'reactstrap';
import imgfCheck from '../../assets/img/check.png';
import imgfFirstStepsDocument from '../../assets/img/primeiros_passos-documento.png';
import imgfFirstStepsGroup from '../../assets/img/primeiros_passos-grupo.png';
import imgfFirstStepsNorm from '../../assets/img/primeiros_passos-norma.png';
import imgfFirstStepsFolder from '../../assets/img/primeiros_passos-pasta.png';
import imgfFirstStepsUser from '../../assets/img/primeiros-passos_usuario.png';
import imgfNoPic from '../../assets/img/nopic.svg';
import Axios from '../../config/Axios';
import url from '../../config/Url';
import Page from '../default-v2/Page';
import NewVersion from '../document/NewVersion';
import MyDocument from '../myaccount/MyDocument';
import RequestModal from '../request/RequestModal';
import DashboardBlock from './DashboardBlock';
import { myPendingDocuments, documentsIAmResponsibleFor } from '../../actions/ActionDocumentPending';
import { myPendingReadings } from '../../actions/ActionReadPending';
import { deleteRequest } from '../../actions/ActionRequest';
import { createAndRedirect } from '../../actions/ActionDocumentEditor';
import Document from '../document/Document';
import ResponsibleReadModal from './ResponsibleReadModal';
import ScrollBar from '../../components/ScrollBar';
import DashboardGroupBlock from './DashboardGroupBlock';
import imgfModuleQuality from '../default-v2/img/icon-module-quality-panel.svg';
import imgfModuleOperational from '../default-v2/img/icon-module-operational-panel.svg';
import imgfModuleAudit from '../default-v2/img/icon-module-audit-management.svg';
import imgfModuleDocument from '../default-v2/img/icon-module-documents.svg';
import IconStatus from '../../components/IconStatus';
import Breadcrumb from '../../components/Breadcrumbs';
import './styles/dashboard.scss';
import { canAtLeast } from '../../config/Permissions';
import homeImg from '../../assets/img/home.svg';
import { oLocalStorage } from '../../config/EncodedLocalStorage';
import { getDocumentTitle } from '../../utils/tools';
import { useAppearanceContext } from '../../config/ApperanceContext';

export class Dashboard extends Component {
  constructor(oProps) {
    super(oProps);
    const { oLocation } = this.props;

    moment.locale(oLocalStorage.get('cLanguage'));

    this.state = {
      bLoading: true,
      aPendingRequests: [],
      aQuickAccessDocuments: [],

      cUserName: '',
      nPendencies: '',
      nActivitiesPending: 0,
      aQualityPanel: [],
      aAuditManagement: [],
      aPendingActivities: [],
      aPendingDelegatedReadings: [],
      aPendingReadingsResponsible: [],
      aPendingDelegatedEdits: [],
      aPendingEditsResponsible: [],
      aPendingReads: [],
      aPendingDocuments: [],
      aFirstSteps: [],
      aResponsibleDocuments: [],
      aResponsibleReads: [],
      aDocumentsWithoutBonds: [],
      oAvailableSpace: {},
      bRun: false,
      aSteps: [
        {
          rcmpContent: <h2>{intl.get('Dashboard.comecar')}</h2>,
          cPlacement: 'center',
          oLocale: {
            rcmpSkip: <strong aria-label="skip">{intl.get('Dashboard.pular_intro')}</strong>,
            cNext: intl.get('proximo')
          },
          cTarget: 'body'
        },

        {
          rcmpContent: intl.get('Dashboard.menu_principal'),
          cPlacement: 'bottom',
          oLocale: {
            rcmpSkip: <strong aria-label="skip">{intl.get('Dashboard.menu_principal')}</strong>,
            cNext: intl.get('proximo'),
            cBack: intl.get('anterior')
          },
          cTarget: 'header',
          cTitle: intl.get('Dashboard.menu_primeiros_passos')
        },
        {
          cTitle: 'Configurações',
          oLocale: { cNext: intl.get('proximo'), cBack: intl.get('anterior'), cLast: intl.get('finalizar') },
          rcmpContent: (
            <div>
              {intl.get('Dashboard.opcoes_controlar')}
              <br />
              <h3>{intl.get('Dashboard.opcoes_configuracao')}</h3>
            </div>
          ),
          cTarget: '.user-tip',
          cPlacement: 'top'
        }
      ],
      cMessage:
        typeof oLocation !== 'undefined' && typeof oLocation.state !== 'undefined' ? oLocation.state.cMessage : '',
    };
  }

  componentDidMount() {
    const { setAlert } = this.props;
    const { cMessage } = this.state;

    setAlert('info', cMessage);

    this.getDashboard();
    this.getPendingActivities();
    this.validateSynchronization();
  }

  validateSynchronization = () => {
    const { match } = this.props;
    const cCompanyName = oLocalStorage.get('cCompanyName');
    const cOldCompanyName = sessionStorage.getItem('cOldCompanyName');
    if (
      match.params &&
      match.params.nSynchronization &&
      match.params.nSynchronization === '1' &&
      cCompanyName !== cOldCompanyName
    ) {
      this.setState(
        {
          rcmpAlert: (
            <SweetAlert
              closeOnClickOutside={false}
              confirmBtnText={intl.get('confirmar')}
              title={intl.get('Dashboard.synchronization_warning_title')}
              onConfirm={this.hideAlert}
            >
              {intl.getHTML('Dashboard.synchronization_warning_message', {
                cOldName: sessionStorage.getItem('cOldCompanyName'),
                cNewName: oLocalStorage.get('cCompanyName')
              })}
            </SweetAlert>
          )
        },
        () => {
          sessionStorage.setItem('cOldCompanyName', oLocalStorage.get('cCompanyName'));
        }
      );
    }
  };

  handleJoyrideCallback = oData => {
    const { status } = oData;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      this.setState({ bRun: false });
    }
  };

  getPendingReads = () => {
    const { setAlert } = this.props;

    return myPendingReadings()
      .then(cPendingReads => this.setState({ aPendingReads: cPendingReads.data.slice(0, 5) }))
      .catch(oError => setAlert('error', oError.aMsgErrors));
  };

  getResponsibleDocuments = () => {
    const { setAlert } = this.props;

    return documentsIAmResponsibleFor()
      .then(cResponsibleDocuments => this.setState({ aResponsibleDocuments: cResponsibleDocuments.slice(0, 5) }))
      .catch(oError => {
        setAlert('error', oError.aMsgErrors);
      });
  };

  getPendingDocuments = () => {
    const { setAlert } = this.props;

    return myPendingDocuments()
      .then(cPendingDocuments => this.setState({ aPendingDocuments: cPendingDocuments.slice(0, 5) }))
      .catch(oError => {
        setAlert('error', oError.aMsgErrors);
      });
  };

  getResponsibleReads = () => {
    const { setAlert } = this.props;
    const bLimit = true;

    return Axios.get('read', {
      params: {
        bLimit
      }
    })
      .then(oRes => {
        this.setState({
          aResponsibleReads: oRes.data.aInfos
        });
      })
      .catch(oError => {
        setAlert('error', oError.aMsgErrors);
      });
  };

  getDashboard = () => {
    const { setAlert } = this.props;

    return Axios.get('dashboard/home')
      .then(oResponse => {
        this.setState({
          ...oResponse.data,
          bLoading: false
        });
      })
      .catch(oError => {
        setAlert('error', oError.aMsgErrors);
      });
  };

  getPendingActivities = () => {
    const { setAlert } = this.props;

    return Axios.get('/operationa-panel-activity/report', {
      params: { perPage: 3, order: 'asc', orderBy: 'activity_ddeadline' }
    })
      .then(oResponse => {
        this.setState({
          aPendingActivities: oResponse.data.data.data,
          nActivitiesPending: oResponse.data.data.total,
          bLoading: false
        });
      })
      .catch(oError => {
        setAlert('error', oError.aMsgErrors);
      });
  };

  openRequest = oRequest => {
    this.openModalRequest(oRequest);
  };

  confirmRemoveRequest = nRequestId => {
    const { setAlert } = this.props;
    const { aPendingRequests } = this.state;

    deleteRequest(nRequestId)
      .then(oResponse => {
        setAlert('success', oResponse.data.success);
        this.hideAlert();
        this.setState({
          aPendingRequests: aPendingRequests.filter(oRqstIndex => {
            return oRqstIndex.rqst_nid !== nRequestId;
          })
        });
      })
      .catch(oError => {
        setAlert('error', oError.aMsgErrors);
        this.hideAlert();
      });
  };

  removeRequest = nRequestId => {
    this.setState({
      rcmpAlert: (
        <SweetAlert
          danger
          title={intl.get('Request.remocao_titulo')}
          confirmBtnText={intl.get('confirmar')}
          cancelBtnText={intl.get('cancelar')}
          cancelBtnBsStyle="danger"
          showCancel
          onCancel={this.hideAlert}
          onConfirm={evt => this.confirmRemoveRequest(nRequestId)}
        >
          {intl.get('Request.remover_pergunta')}
        </SweetAlert>
      )
    });
  };

  openNewVersionModal = oRequest => {
    const { history } = this.props;
    Axios.get(`version/validate/new-version/${oRequest.doc_nid}`)
      .then(oResponse => {
        this.setState({
          rcmpAlert: (
            <SweetAlert customClass="modal-edit md scrollBar" title="" onConfirm={this.hideAlert} showConfirm={false}>
              <div className="head-modal">
                {intl.get('Dashboard.nova_versao')}
                <span
                  aria-labelledby="head-modal"
                  tabIndex={0}
                  onClick={this.hideAlert}
                  className="close"
                  role="button"
                  onKeyPress={this.hideAlert}
                />
              </div>
              <ScrollBar>
                <NewVersion
                  nRequestId={oRequest.rqst_nid}
                  onSuccess={this.successMessage}
                  closeModal={this.hideAlert}
                  history={history}
                />
              </ScrollBar>
            </SweetAlert>
          )
        });
      })
      .catch(oError => {
        history.push({
          state: { aAlertMessages: { cType: 'error', cMsg: oError.aMsgErrors } }
        });
      });
  };

  openModalRequest = oRequest => {
    const { history, setAlert } = this.props;

    this.setState({
      rcmpAlert: (
        <RequestModal
          oRequest={oRequest}
          onSuccess={this.successMessage}
          onError={this.errorRequest}
          hideAlert={this.hideAlert}
          history={history}
          setAlert={setAlert}
        />
      )
    });
  };

  successMessage = cMsg => {
    const { setAlert } = this.props;
    this.hideAlert();
    setAlert('success', cMsg);
    this.getDashboard();
  };

  hideAlert = () => {
    this.setState({
      rcmpAlert: null
    });
    this.getDashboard();
  };

  openRoute = (cRoute, nVersionId) => {
    const { history } = this.props;
    if (cRoute === 'version.show') history.push(`/document/detail/${nVersionId}`);
    else if (cRoute === 'version.edit') history.push(`/document/editor/${nVersionId}`);
    else if (cRoute === 'version.review') history.push(`/document/review/${nVersionId}`);
    else if (cRoute === 'version.publish') history.push(`/document/publish/${nVersionId}`);
  };

  openResponsibleReadModal = nVersionId => {
    const { history, setAlert } = this.props;

    this.setState({
      rcmpAlert: (
        <SweetAlert
          customClass="modal-edit md disabled-overflow scrollBar"
          title=""
          onConfirm={this.hideAlert}
          showConfirm={false}
        >
          <div className="head-modal">
            {intl.get('DocumentReading.title')}
            <span
              aria-labelledby="head-modal"
              tabIndex={0}
              onClick={this.hideAlert}
              className="close"
              role="button"
              onKeyPress={this.hideAlert}
            />
          </div>
          <ScrollBar>
            <ResponsibleReadModal
              nVersionId={nVersionId}
              onSuccess={this.successMessage}
              onError={this.errorRequest}
              hideAlert={this.hideAlert}
              history={history}
              setAlert={setAlert}
            />
          </ScrollBar>
        </SweetAlert>
      )
    });
  };

  getDate = oPendingActivityItem => {
    if (!oPendingActivityItem || !oPendingActivityItem.activity_ddeadline)
      return intl.get('ActivitiesPending.without_deadline');
    return (
      moment
        .parseZone(oPendingActivityItem.activity_ddeadline)
        .tz(oLocalStorage.get('cTimezone'))
        .format('DD/MM/YYYY  HH:mm') ?? intl.get('ActivitiesPending.without_deadline')
    );
  };

  compareDate = oPendingActivityItem => {
    if (!oPendingActivityItem || !oPendingActivityItem.activity_ddeadline) return false;
    // return true or false when the date is greater than today
    return moment.parseZone(oPendingActivityItem.activity_ddeadline).isBefore(moment());
  };

  renderPendingActivities = () => {
    const { aPendingActivities } = this.state;

    if (aPendingActivities.length === 0) {
      return '';
    }

    return aPendingActivities.map(oPendingActivityItem => (

      <tr
        key={oPendingActivityItem.activity_nid} // Adicione a key aqui
        className="row-pending-responsible-reading"
        onKeyPress={evt =>
          window.location.replace(
            `/dashboard/${oPendingActivityItem.panel_nid}/boards/${oPendingActivityItem.board_nid}/${oPendingActivityItem.block_nid}/${oPendingActivityItem.activity_nid}/`
          )
        }
        onClick={evt =>
          window.location.replace(
            `/dashboard/${oPendingActivityItem.panel_nid}/boards/${oPendingActivityItem.board_nid}/${oPendingActivityItem.block_nid}/${oPendingActivityItem.activity_nid}/`
          )
        }
      >
        <td>
          <span>{oPendingActivityItem.activity_nid}</span>
        </td>
        <td>
          <span className={this.compareDate(oPendingActivityItem) ? 'expired' : ''}>
            {this.getDate(oPendingActivityItem)}
          </span>
        </td>
        <td>
          <span title={oPendingActivityItem.panel_cname}>{oPendingActivityItem.panel_cname}</span>
        </td>
        <td>
          <span title={oPendingActivityItem.board_cname}>{oPendingActivityItem.board_cname}</span>
        </td>
        <td>
          <span title={oPendingActivityItem.block_cname}>{oPendingActivityItem.block_cname}</span>
        </td>
      </tr>

    ));
  };

  renderResponsibleRead = () => {
    const { aResponsibleReads } = this.state;
    const elInformation = Object.keys(aResponsibleReads)
      .slice(0, 5)
      .map(cKey => {
        const oInformationItem = aResponsibleReads[cKey];
        return (
          <li
            role="button"
            tabIndex="0"
            key={oInformationItem.oVersion.ver_nid}
            className="request"
            onClick={() => this.openResponsibleReadModal(oInformationItem.oVersion.ver_nid)}
            onKeyPress={() => this.openResponsibleReadModal(oInformationItem.oVersion.ver_nid)}
          >
            <h3>{getDocumentTitle(oInformationItem.oVersion.document.doc_ctitle)}</h3>
            <p className="notread">{oInformationItem.nQuantityNotRead}</p>
            <p className="status">
              {moment
                .parseZone(oInformationItem.oVersion.ver_dpublished)
                .tz(oLocalStorage.get('cTimezone'))
                .format('DD/MM/YYYY  HH:mm:ss')}
            </p>
          </li>
        );
      });

    return <h1>{elInformation}</h1>;
  };

  renderFirstSteps = () => {
    const { aFirstSteps } = this.state;
    const { history } = this.props;

    return (
      <ol className="firstSteps">
        <li>
          <img src={imgfFirstStepsUser} alt={intl.get('Dashboard.crie_usuario')} />
          <p>1. {intl.get('Dashboard.crie_usuario')}</p>
          {aFirstSteps.bCreateUser ? (
            <Button outline className="btn alternate back" onClick={() => history.push('invite-users')}>
              {intl.get('InviteUsers.botao_convidar')}
            </Button>
          ) : (
            <img src={imgfCheck} className="check" alt={intl.get('Dashboard.crie_usuario')} />
          )}
        </li>
        <li>
          <img src={imgfFirstStepsGroup} alt={intl.get('Dashboard.crie_equipe')} />
          <p>2. {intl.get('Dashboard.crie_equipe')}</p>
          {aFirstSteps.bCreateTeam ? (
            <Button outline className="btn alternate back" onClick={() => history.push('team/create')}>
              {intl.get('PermissionGroup.titulo_criacao')}
            </Button>
          ) : (
            <img src={imgfCheck} className="check" alt={intl.get('Dashboard.crie_equipe')} />
          )}
        </li>
        <li>
          <img src={imgfFirstStepsFolder} alt={intl.get('Dashboard.crie_pasta')} />
          <p>3. {intl.get('Dashboard.crie_pasta')}</p>
          {aFirstSteps.bCreateFolder ? (
            <Button outline className="btn alternate back" onClick={() => history.push('folder')}>
              {intl.get('SmartButton.criar_pasta')}
            </Button>
          ) : (
            <img src={imgfCheck} className="check" alt={intl.get('Dashboard.crie_pasta')} />
          )}
        </li>
        <li>
          <img src={imgfFirstStepsDocument} alt={intl.get('Dashboard.crie_documento')} />
          <p>4. {intl.get('Dashboard.crie_documento')}</p>
          {aFirstSteps.bCreateDocument ? (
            <Button outline className="btn alternate back" onClick={this.openCreateDocumentModal}>
              {intl.get('SmartButton.criar_documento')}
            </Button>
          ) : (
            <img src={imgfCheck} className="check" alt={intl.get('Dashboard.crie_documento')} />
          )}
        </li>
        <li>
          <img src={imgfFirstStepsNorm} alt={intl.get('Dashboard.crie_norma')} />
          <p>5. {intl.get('Dashboard.crie_norma')}</p>
          {aFirstSteps.bCreateNorm ? (
            <Button outline className="btn alternate back" onClick={() => history.push('quality-panel')}>
              {intl.get('SmartButton.criar_norma')}
            </Button>
          ) : (
            <img src={imgfCheck} className="check" alt={intl.get('Dashboard.crie_norma')} />
          )}
        </li>
      </ol>
    );
  };

  openCreateDocumentModal = nRequestId => {
    createAndRedirect('request', nRequestId).catch(oError => {
      this.setState({
        rcmpAlert: (
          <SweetAlert
            warning
            showCancel
            customClass="modal-edit modalMedium scrollBar"
            cancelBtnText={intl.get('close')}
            cancelBtnBsStyle="danger"
            onCancel={this.hideAlert}
            title={intl.get('create_document_error')}
            showConfirm={false}
          >
            {oError.response.data}
          </SweetAlert>
        )
      });
    });
  };

  showFirstSteps = () => {
    if (localStorage.getItem('bShowFirstSteps') === 'false') return false;
    return true;
  };

  closeFirstSteps = () => {
    localStorage.setItem('bShowFirstSteps', false);
    this.setState({
      aFirstSteps: []
    });
  };

  errorRequest = nRequestId => {
    const { aPendingRequests } = this.state;
    this.setState({
      aPendingRequests: aPendingRequests.filter(oRqstIndex => {
        return oRqstIndex.rqst_nid !== nRequestId;
      })
    });
  };

  renderQualityPanel = () => {
    const { aQualityPanel } = this.state;
    const { history } = this.props;

    if (aQualityPanel.length == 0) {
      return '';
    }

    return aQualityPanel.map(oQualityItem => (
      <tr
        onKeyPress={evt => {
          history.push(`/requisite/${oQualityItem.norm_nid}`);
        }}
        onClick={evt => {
          history.push(`/requisite/${oQualityItem.norm_nid}`);
        }}
        key={oQualityItem.req_nid}
        className="row-quality-panel"
      >
        <td title={oQualityItem.req_cname}>
          <span>{`${oQualityItem.cOrderRequisite} - ${oQualityItem.req_cname}`}</span>
        </td>
        <td title={oQualityItem.norm_cinitials}>{oQualityItem.norm_cinitials}</td>
        <td>
          {oQualityItem.req_dedited
            ? moment
              .parseZone(oQualityItem.req_dedited)
              .tz(oLocalStorage.get('cTimezone'))
              .format('LL')
            : intl.get('Dashboard.without_changes')}
        </td>
        <td className="row-quality-panel-compliance">
          {oQualityItem.notConformRequisite > 0 ? (
            <i title={intl.get('Dashboard.not_compliance')} className="non-compliance" />
          ) : (
            <i title={intl.get('Dashboard.compliance')} className="compliance" />
          )}
        </td>
      </tr>
    ));
  };

  renderAuditManagement = () => {
    const { aAuditManagement } = this.state;
    const { history } = this.props;

    if (aAuditManagement.length == 0) {
      return '';
    }

    return aAuditManagement.map((oAuditItem, nIndex) => (
      <tr
        onKeyPress={evt => {
          history.push(`/requisite-audit/${oAuditItem.audi_nid}`);
        }}
        onClick={evt => {
          history.push(`/requisite-audit/${oAuditItem.audi_nid}`);
        }}
        key={oAuditItem.audi_nid}
        className="row-audit-management"
      >
        <td title={oAuditItem.norm_cinitials}>{oAuditItem.norm_cinitials}</td>
        <td>{`${moment
          .parseZone(oAuditItem.audi_dexecutionbegin)
          .tz(oLocalStorage.get('cTimezone'))
          .format('DD/MM/YYYY')} ${intl.get('Dashboard.dates_period')} ${moment
            .parseZone(oAuditItem.audi_dexecutionend)
            .tz(oLocalStorage.get('cTimezone'))
            .format('DD/MM/YYYY')}`}</td>
        <td>
          {oAuditItem.locl_ccity ? `${oAuditItem.locl_ccity}-${oAuditItem.locl_cstate}` : ''}
        </td>
      </tr>
    ));
  };

  renderPendingDelegatedReading = () => {
    const { aPendingDelegatedReadings } = this.state;
    const { history } = this.props;

    if (aPendingDelegatedReadings.length == 0) {
      return '';
    }

    return aPendingDelegatedReadings.map(oPendingDocumentItem => (
      <tr
        onKeyPress={evt => {
          history.push(`/document/detail/${oPendingDocumentItem.ver_nid}`);
        }}
        onClick={evt => {
          history.push(`/document/detail/${oPendingDocumentItem.ver_nid}`);
        }}
        key={oPendingDocumentItem.ver_nid}
        className="row-pending-delegated-reading"
      >
        <td title={getDocumentTitle(oPendingDocumentItem.doc_ctitle)}>
          <span>{getDocumentTitle(oPendingDocumentItem.doc_ctitle)}</span>
        </td>
        <td title={oPendingDocumentItem?.user_cname}>
          <span>{oPendingDocumentItem?.user_cname}</span>
        </td>
        <td className="action">
          <i className="icon-reading" title={intl.get('Status.awaiting_reading')} />
        </td>
        <td>
          {moment
            .parseZone(oPendingDocumentItem.ver_dpublished)
            .tz(oLocalStorage.get('cTimezone'))
            .format('LL')}
        </td>
      </tr>
    ));
  };

  renderPendingResponsibledReading = () => {
    const { aPendingReadingsResponsible } = this.state;

    if (aPendingReadingsResponsible.length == 0) {
      return '';
    }

    return aPendingReadingsResponsible.map(oPendingDocumentItem => (
      <tr
        onClick={() => this.openResponsibleReadModal(oPendingDocumentItem.ver_nid)}
        onKeyPress={() => this.openResponsibleReadModal(oPendingDocumentItem.ver_nid)}
        key={oPendingDocumentItem.ver_nid}
        className="row-pending-responsible-reading"
      >
        <td title={getDocumentTitle(oPendingDocumentItem.doc_ctitle)}>
          <span>{getDocumentTitle(oPendingDocumentItem.doc_ctitle)}</span>
        </td>
        <td title={oPendingDocumentItem.user_cname}>
          <span>{oPendingDocumentItem.user_cname}</span>
        </td>
        <td className="action">
          <i className="icon-reading" title={intl.get('Status.awaiting_reading')} />
        </td>
      </tr>
    ));
  };

  renderPendingDelegateddEdits = () => {
    const { aPendingDelegatedEdits } = this.state;

    if (aPendingDelegatedEdits.length == 0) {
      return '';
    }

    return aPendingDelegatedEdits.map(oPendingDocumentItem => (
      <tr
        onClick={evt => this.openRoute(oPendingDocumentItem.cRoute, oPendingDocumentItem.ver_nid)}
        onKeyPress={evt => this.openRoute(oPendingDocumentItem.cRoute, oPendingDocumentItem.ver_nid)}
        key={oPendingDocumentItem.ver_nid}
        className="row-pending-delegated-edits"
      >
        <td title={getDocumentTitle(oPendingDocumentItem.doc_ctitle)}>
          <span>{getDocumentTitle(oPendingDocumentItem.doc_ctitle)}</span>
        </td>
        <td title={oPendingDocumentItem.user_cname}>
          <span>{oPendingDocumentItem.user_cname}</span>
        </td>
        <td className="action">
          <IconStatus cStatus={oPendingDocumentItem.stat_cslugname} onClick={evt => { }} />
        </td>

        <td>
          {moment
            .parseZone(oPendingDocumentItem.cDataDiffStandard)
            .tz(oLocalStorage.get('cTimezone'))
            .format('LL')}
        </td>
      </tr>
    ));
  };

  renderPendingResponsibledEdits = () => {
    const { aPendingEditsResponsible } = this.state;
    const { history, setAlert } = this.props;

    if (aPendingEditsResponsible.length == 0) {
      return '';
    }

    return (
      <MyDocument
        bIsMyAccountPage={false}
        aPendingEditsResponsible={aPendingEditsResponsible}
        fnUpdateComponent={this.getDashboard}
        history={history}
        setAlert={setAlert}
      />
    );
  };

  renderPendingRequests = () => {
    const { aPendingRequests } = this.state;

    if (aPendingRequests.length == 0) {
      return '';
    }

    return aPendingRequests.map((oReqItem, nIndex) => (
      <tr
        className="row-pending-requests"
        onKeyPress={evt => this.openRequest(oReqItem)}
        onClick={evt => this.openRequest(oReqItem)}
        key={oReqItem.rqst_nid}
      >
        <td>
          <div className="row-pending-requests-user">
            <img
              src={oReqItem.requester_cavatar ? oReqItem.equester_cavatar : imgfNoPic}
              alt={oReqItem.requester_cname}
            />
            <span title={oReqItem.requester_cname}>{oReqItem.requester_cname}</span>
          </div>
        </td>
        <td>
          <div className="row-pending-requests-user">
            <img
              src={oReqItem.destiny_cavatar ? oReqItem.destiny_cavatar : imgfNoPic}
              alt={oReqItem.destiny_cname}
            />
            <span title={oReqItem.destiny_cname}>{oReqItem.destiny_cname}</span>
          </div>
        </td>
        <td title={oReqItem.rqst_ctitle}>
          <span>{oReqItem.rqst_ctitle}</span>
        </td>
        <td
          title={moment
            .parseZone(oReqItem.rqst_drequestedupdated)
            .tz(oLocalStorage.get('cTimezone'))
            .format('LL')}
        >
          {moment
            .parseZone(oReqItem.rqst_drequestedupdated)
            .tz(oLocalStorage.get('cTimezone'))
            .format('LL')}
        </td>
      </tr>
    ));
  };

  render() {
    const { rcmpAlert, cUserName, nPendencies, nActivitiesPending, bLoading } = this.state;

    const rcmpBreadcrumb = <Breadcrumb />;
    return (
      <Page
        loading={bLoading ? 1 : 0}
        rcmpBreadcrumb={rcmpBreadcrumb}
        cTitle={intl.get('Dashboard.home')}
        cImage={homeImg}
        className="v2-dashboard"
        cCurrentSideMenuOption={intl.get('home')}
      >
        {rcmpAlert}
        <DashboardHeader
          nPendencies={nPendencies}
          nActivitiesPending={nActivitiesPending}
          cUserName={cUserName}
        />

        <section id="v2-dashboard-group-blocks">
          {canAtLeast('quality-panel', 'quality-panel') && (
            <DashboardGroupBlock
              cIcon={imgfModuleQuality}
              cIconName={intl.get('Nav.quality_panel')}
              cTitle={intl.get('Nav.quality_panel')}
            >
              <DashboardBlock
                cLowerLink="/quality-panel"
                cLowerLinkText={intl.get('Dashboard.show_all_2')}
                aColumn={[
                  { cName: intl.get('Dashboard.requisite'), cClassName: 'align-column-colspan-3' },
                  { cName: intl.get('Dashboard.belongs_norm'), cClassName: 'align-column' },
                  { cName: intl.get('Dashboard.last_update'), cClassName: '' },
                  { cName: intl.get('Dashboard.action'), cClassName: 'row-action' }
                ]}
                bIsFirstBlock
              >
                {this.renderQualityPanel()}
              </DashboardBlock>
            </DashboardGroupBlock>
          )}

          {canAtLeast('audit-management', 'audit-management') && (
            <DashboardGroupBlock
              cIcon={imgfModuleAudit}
              cIconName={intl.get('Nav.audit_management')}
              cTitle={intl.get('Nav.audit_management')}
            >
              <DashboardBlock
                cLowerLink="/audit-management"
                cLowerLinkText={intl.get('Dashboard.show_all_2')}
                aColumn={[
                  { cName: intl.get('Dashboard.norm_in_progress'), cClassName: 'align-column-colspan-2' },
                  { cName: intl.get('Dashboard.period'), cClassName: 'align-column' },
                  { cName: intl.get('Dashboard.place'), cClassName: '' }
                ]}
                bIsFirstBlock
              >
                {this.renderAuditManagement()}
              </DashboardBlock>
            </DashboardGroupBlock>
          )}

          {canAtLeast('documents', 'documents') && (
            <DashboardGroupBlock
              cIcon={imgfModuleDocument}
              cIconName={intl.get('Dashboard.pending_reading_documents')}
              cTitle={intl.get('Dashboard.pending_reading_documents')}
            >
              <DashboardBlock
                cLowerLink="/report/reading-document-delegate"
                cLowerLinkText={intl.get('Dashboard.show_all_2')}
                aColumn={[
                  { cName: intl.get('Dashboard.documents_delegated_to_me'), cClassName: 'align-column-2' },
                  { cName: intl.get('Dashboard.request_by'), cClassName: 'align-column' },
                  { cName: intl.get('Dashboard.action'), cClassName: 'align-column' }
                ]}
                bIsFirstBlock
              >
                {this.renderPendingDelegatedReading()}
              </DashboardBlock>

              <DashboardBlock
                cLowerLink="/report/reading-document-responsible"
                cLowerLinkText={intl.get('Dashboard.show_all_2')}
                aColumn={[
                  { cName: intl.get('Dashboard.documents_i_am_responsible'), cClassName: 'align-column-2' },
                  { cName: intl.get('Dashboard.user'), cClassName: 'align-column' },
                  { cName: intl.get('Dashboard.action'), cClassName: 'align-column' }
                ]}
              >
                {this.renderPendingResponsibledReading()}
              </DashboardBlock>
            </DashboardGroupBlock>
          )}

          {canAtLeast('documents', 'documents') && (
            <DashboardGroupBlock
              cIcon={imgfModuleDocument}
              cIconName={intl.get('Dashboard.pending_edits_documents')}
              cTitle={intl.get('Dashboard.pending_edits_documents')}
            >
              <DashboardBlock
                cLowerLink="/report/edits-document-delegate"
                cLowerLinkText={intl.get('Dashboard.show_all_2')}
                aColumn={[
                  { cName: intl.get('Dashboard.documents_delegated_to_me'), cClassName: 'align-column-2' },
                  { cName: intl.get('Dashboard.request_by'), cClassName: 'align-column' },
                  { cName: intl.get('Dashboard.action'), cClassName: 'align-column' },
                  { cName: intl.get('Dashboard.expiration_on'), cClassName: 'align-column' }
                ]}
                bIsFirstBlock
              >
                {this.renderPendingDelegateddEdits()}
              </DashboardBlock>

              <DashboardBlock
                cLowerLink="/report/edits-document-responsible"
                cLowerLinkText={intl.get('Dashboard.show_all_2')}
                aColumn={[
                  { cName: intl.get('Dashboard.documents_i_am_responsible'), cClassName: 'align-column-2' },
                  { cName: intl.get('Dashboard.user'), cClassName: 'align-column' },
                  { cName: intl.get('Dashboard.action'), cClassName: 'align-column' },
                  { cName: intl.get('Dashboard.document_status'), cClassName: 'align-column' },
                  { cName: intl.get('Dashboard.expiration_on'), cClassName: 'align-column' }
                ]}
              >
                {this.renderPendingResponsibledEdits()}
              </DashboardBlock>
            </DashboardGroupBlock>
          )}

          {canAtLeast('documents', 'documents') && (
            <DashboardGroupBlock cTitle={intl.get('Dashboard.pending_requests')}>
              <DashboardBlock
                cTitle={intl.get('Dashboard.pending_requests')}
                cLowerLinkText={intl.get('Dashboard.show_all_2')}
                cLowerLink="/report/request"
                aColumn={[
                  { cName: intl.get('Dashboard.sent_by'), cClassName: 'align-column-2' },
                  { cName: intl.get('Dashboard.received_by'), cClassName: 'align-column-3' },
                  { cName: intl.get('Dashboard.activity'), cClassName: 'align-column' },
                  { cName: intl.get('Dashboard.sent_on'), cClassName: 'align-column' }
                ]}
                bIsFirstBlock
              >
                {this.renderPendingRequests()}
              </DashboardBlock>
            </DashboardGroupBlock>
          )}

          {canAtLeast('operational-panel', 'operational-panel') && (
            <DashboardGroupBlock
              key = 'operational-panel-1'
              cTitle={intl.get('ActivitiesPending.title')}
              cIcon={imgfModuleOperational}
              cIconName={intl.get('ActivitiesPending.title')}
            >
              <DashboardBlock
                key="dashboard-block-1"
                cTitle={intl.get('ActivitiesPending.title')}
                cLowerLinkText={intl.get('ActivitiesPending.show_all')}
                cLowerLink="/report/activities-pending"
                aColumn={[
                  { cName: 'ID', cClassName: 'align-column' },
                  { cName: intl.get('ActivitiesPending.deadline'), cClassName: 'align-column' },
                  { cName: intl.get('ActivitiesPending.panel'), cClassName: 'align-column' },
                  { cName: intl.get('ActivitiesPending.board'), cClassName: 'align-column' },
                  { cName: intl.get('ActivitiesPending.block'), cClassName: 'align-column' }
                ]}
              >
                {this.renderPendingActivities()}
              </DashboardBlock>
            </DashboardGroupBlock>
          )}
        </section>
      </Page>
    );
  }
}

const DashboardHeader = ({ nPendencies, nActivitiesPending, cUserName }) => {
  const { getBrandImgSrc } = useAppearanceContext();
  const cBackgroundUrl = getBrandImgSrc('home-header-422x349.svg');
  const cBackgroundStr = `url(${cBackgroundUrl})`;
  console.log(cBackgroundStr);
  return (
    <section
      id="v2-dashboard-welcome"
      style={{ backgroundImage: cBackgroundStr }}
    >
      <h1>
        {intl.getHTML('Dashboard.welcome_name', { name: cUserName })} <br />
        {intl.getHTML('Dashboard.welcome_pendencies', {
          pendencies: `${Number(nPendencies) + nActivitiesPending}`
        })}
      </h1>
    </section>

  )

};

export default Dashboard;
