import React from 'react';
import { NavLink } from 'react-router-dom';
import intl from 'react-intl-universal';

const LimitWidget = (oProps) => {
  const { oLimits } = oProps;
  if (
    oLimits &&
    ((oLimits.oUser !== undefined && oLimits.oUser.nAllowed !== -1) ||
      (oLimits.oDocument !== undefined && oLimits.oDocument.nAllowed !== -1))
  ) {
    let nPercent = oLimits.oUser
      ? oLimits.oUser.nLeft / oLimits.oUser.nAllowed - 1
      : oLimits.oDocument.nLeft / oLimits.oDocument.nAllowed - 1;
    nPercent = nPercent * 100 * -1;
    nPercent = Number(nPercent).toFixed(2);

    if (nPercent > 100) {
      nPercent = 100;
    } else if (nPercent < -100) {
      nPercent = -100;
    }

    return (
      <div className="limit-box">
        <p className="txt-limit">
          {(oLimits.oUser && oLimits.oUser.nLeft !== 0) ||
            (oLimits.oDocument && oLimits.oDocument.nLeft !== 0) ? (
            <>
              {intl.get('LimitWidget.plan_limite')}{' '}
              {oLimits.oUser ? oLimits.oUser.nLeft : oLimits.oDocument.nLeft}{' '}
              {oLimits.oUser ? `${intl.get('invitation')}.` : intl.get('modulo_documentos')}
            </>
          ) : (
            <>
              {intl.get('LimitWidget.reached_limit')}{' '}
              {oLimits.oUser ? oLimits.oUser.nAllowed : oLimits.oDocument.nAllowed}{' '}
              {oLimits.oUser ? `${intl.get('invitation')}.` : intl.get('modulo_documentos')}
            </>
          )}
        </p>

        <a href="/dashboard/plan">
          {intl.get('LimitWidget.update_plan')}
        </a>
      </div>
    );
  }
  return null;
};

export default LimitWidget;
